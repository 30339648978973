import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Report, ReportPart, ReportLine } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { chargeReceiptMessages } from '../chargeReceiptMessages'
import { useStoreSelector } from '../store'
import type { BillingHistoryModel } from '../store/preloadedState'

export function ChargeReceiptReport() {
  const { billingHistory, billingHistoryBundle } = useStoreSelector(state => state)
  const { formatMessage } = useLocales()

  return (
    <Report>
      <ReportPart
        caption={[
          formatMessage(chargeReceiptMessages.receiptPageDescriptionLabel),
          formatMessage(chargeReceiptMessages.receiptPagePriceLabel),
        ]}
      >
        {billingHistoryBundle && billingHistoryBundle?.length > 0 ? (
          billingHistoryBundle.map(item => <ChargeReceiptReportItem key={item.id} billingHistory={item} />)
        ) : (
          <ChargeReceiptReportItem key="single" billingHistory={billingHistory} />
        )}
        <ReportLine name={<Text fontWeight="bold">{formatMessage(chargeReceiptMessages.receiptPageTotalLabel)}</Text>}>
          <Text fontWeight="bold" data-test="total-price">
            {billingHistory.lineAmountFormatted}
          </Text>
        </ReportLine>
      </ReportPart>
    </Report>
  )
}

function ChargeReceiptReportItem({ billingHistory }: { billingHistory: BillingHistoryModel }) {
  const { formatMessage } = useLocales()
  const receiptPageLabel = useMemo(
    () =>
      billingHistory.isRefund
        ? formatMessage(chargeReceiptMessages.receiptPageRefundLabel)
        : formatMessage(chargeReceiptMessages.receiptPageChargeLabel),
    [billingHistory.isRefund, formatMessage]
  )

  const descriptor = useMemo(() => {
    if (billingHistory.actual) {
      return `${receiptPageLabel}
          ${formatMessage(chargeReceiptMessages.commonForText)}
          ${billingHistory.actual?.arrivalTimeFormatted}
          ${formatMessage(chargeReceiptMessages.receiptPageReservationOnText)}
          ${billingHistory.actual?.dateFormatted}`
    }
    return formatMessage(chargeReceiptMessages.commonSubTotalText)
  }, [billingHistory.actual, formatMessage, receiptPageLabel])

  let amount = ''

  if (billingHistory.baseAmount > 0 && (billingHistory.gratuity || billingHistory.tax)) {
    amount = billingHistory.baseAmountFormatted
  } else if (billingHistory.lineAmount > 0 && !(billingHistory.gratuity || billingHistory.tax)) {
    amount = billingHistory.lineAmountFormatted
  }

  return (
    <>
      <ReportLine name={descriptor}>{amount}</ReportLine>
      {billingHistory.serviceChargeFormatted && (
        <ReportLine name={formatMessage(chargeReceiptMessages.resWidgetServiceChargeLabel)}>
          {billingHistory.serviceChargeFormatted}
        </ReportLine>
      )}
      {billingHistory.tax && <ReportLine name={formatMessage(chargeReceiptMessages.commonTaxText)}>{billingHistory.tax}</ReportLine>}
      {billingHistory.upsellAmount != null && billingHistory.upsellAmount > 0 && (
        <>
          <ReportLine name={formatMessage(chargeReceiptMessages.receiptPageUpgradesLabel)} />
          {billingHistory.selectedUpsells.map(upsell => (
            <ReportLine key={upsell.name} name={upsell.quantity > 1 ? `${upsell.name} (${upsell.quantity})` : upsell.name}>
              {upsell.priceFormatted}
            </ReportLine>
          ))}
        </>
      )}
      {(billingHistory.gratuityAmount || billingHistory.gratuity) && (
        <ReportLine name={formatMessage(chargeReceiptMessages.receiptPageGratuityLabel)}>{billingHistory.gratuity}</ReportLine>
      )}
      {billingHistory.additionalFee && (
        <ReportLine name={formatMessage(chargeReceiptMessages.receiptPageAdminFeeLabel)}>{billingHistory.additionalFee}</ReportLine>
      )}
      {billingHistory.additionalFeeTax && (
        <ReportLine
          name={`${formatMessage(chargeReceiptMessages.receiptPageAdminFeeLabel)} ${formatMessage(chargeReceiptMessages.commonTaxText)}`}
        >
          {billingHistory.additionalFeeTax}
        </ReportLine>
      )}
    </>
  )
}
