/* eslint-disable camelcase */
import camelcaseKeys from 'camelcase-keys'
import type { Venue as BaseVenue } from '@sevenrooms/core/domain'
import { FormatService, type CountryCode } from '@sevenrooms/core/locales'

interface SelectedLanguageStringsModel {
  receipt_page_at_label: string
  receipt_page_text: string
  receipt_page_description_label: string
  receipt_page_price_label: string
  receipt_page_total_label: string
  receipt_page_need_help_text: string
  receipt_page_gratuity_label: string
  receipt_page_upgrades_label: string
  receipt_page_admin_fee_label: string
  receipt_page_charge_label: string
  receipt_page_reservation_on_text: string
  receipt_page_refund_label: string

  common_res_number_text: string
  common_sub_total_text: string
  common_for_text: string
  common_tax_text: string
}

export interface Venue extends BaseVenue {
  phoneNumber: string
  countryCode: CountryCode
  largeLogoUrl: string
}

export interface ActualModel {
  id: string
  arrivalTimeFormatted: string
  dateFormatted: string
  referenceCode: string
}

export interface UpsellModel {
  name: string
  description: string
  price: number
  priceFormatted: string
  quantity: number
}

export interface BillingHistoryModel {
  id: string
  charged: string
  amount: number
  baseAmountFormatted: string
  baseAmount: number
  lineAmountFormatted: string
  lineAmount: number
  serviceChargeFormatted: string
  gratuity: number
  gratuityAmount: number
  additionalFee: number
  additionalFeeTax: number
  tax: number
  brand?: string
  last4?: number
  actual: ActualModel
  isRefund: boolean
  upsellAmount: number
  selectedUpsells: UpsellModel[]
}

interface preloadedStateWindow extends Window {
  PRELOADED: {
    selected_language_strings: SelectedLanguageStringsModel
    base_venue: Venue
    billing_history: BillingHistoryModel
    billing_history_bundle: BillingHistoryModel[]
  }
}

declare let window: preloadedStateWindow

function stringsToClient(data: SelectedLanguageStringsModel) {
  const selectedLanguageStrings = camelcaseKeys(data, { deep: true })
  return Object.entries(selectedLanguageStrings).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: FormatService.formatLocaleStringFromBE(value),
    }),
    { ...selectedLanguageStrings }
  )
}

export const preloadedState = {
  venueInfo: camelcaseKeys(window.PRELOADED.base_venue),
  selectedLanguageStrings: stringsToClient(window.PRELOADED.selected_language_strings),
  billingHistory: camelcaseKeys(window.PRELOADED.billing_history, { deep: true }),
  billingHistoryBundle: camelcaseKeys(window.PRELOADED.billing_history_bundle, { deep: true }),
}

export type PreloadedState = typeof preloadedState
