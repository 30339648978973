import { configureStore } from '@reduxjs/toolkit'
import { type TypedUseSelectorHook, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import { preloadedState, type PreloadedState } from './preloadedState'

interface RootState extends PreloadedState {}

const rootReducer = combineReducers({
  venueInfo: (state = {}) => state,
  selectedLanguageStrings: (state = {}) => state,
  billingHistory: (state = {}) => state,
  billingHistoryBundle: (state = {}) => state,
})

export const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV === 'development',
})

export const useStoreSelector: TypedUseSelectorHook<RootState> = useSelector
