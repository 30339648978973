import { defineMessages } from '@sevenrooms/core/locales'

export const chargeReceiptMessages = defineMessages({
  receiptPageAtLabel: {
    id: 'receiptPageAtLabel',
    defaultMessage: 'at',
  },
  receiptPageText: {
    id: 'receiptPageText',
    defaultMessage: 'Charge for {time} reservation on {date}',
  },
  receiptPageDescriptionLabel: {
    id: 'receiptPageDescriptionLabel',
    defaultMessage: 'Description',
  },
  receiptPagePriceLabel: {
    id: 'receiptPagePriceLabel',
    defaultMessage: 'Price',
  },
  receiptPageTotalLabel: {
    id: 'receiptPageTotalLabel',
    defaultMessage: 'Total',
  },
  receiptPageNeedHelpText: {
    id: 'receiptPageNeedHelpText',
    defaultMessage: 'Have a question or need help? Call {venue_name} at {phone_number}',
  },
  receiptPageGratuityLabel: {
    id: 'receiptPageGratuityLabel',
    defaultMessage: 'gratuity',
  },
  receiptPageUpgradesLabel: {
    id: 'receiptPageUpgradesLabel',
    defaultMessage: 'Upgrades',
  },
  receiptPageAdminFeeLabel: {
    id: 'receiptPageAdminFeeLabel',
    defaultMessage: 'admin fee',
  },
  receiptPageChargeLabel: {
    id: 'receiptPageChargeLabel',
    defaultMessage: 'Charge',
  },
  receiptPageReservationOnText: {
    id: 'receiptPageReservationOnText',
    defaultMessage: 'reservation on',
  },
  receiptPageRefundLabel: {
    id: 'receiptPageRefundLabel',
    defaultMessage: 'Refund',
  },
  commonResNumberText: {
    id: 'commonResNumberText',
    defaultMessage: 'Your reservation number is ',
  },
  commonForText: {
    id: 'commonForText',
    defaultMessage: 'for',
  },
  commonTaxText: {
    id: 'commonTaxText',
    defaultMessage: 'tax',
  },
  commonSubTotalText: {
    id: 'commonSubTotalText',
    defaultMessage: 'sub total',
  },
  resWidgetServiceChargeLabel: {
    id: 'resWidgetServiceChargeLabel',
    defaultMessage: 'Service charge',
  },
})
