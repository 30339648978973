import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Flex, Root, VStack, LogoFooter, TopNav, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { chargeReceiptMessages } from '../chargeReceiptMessages'
import { useStoreSelector } from '../store'
import { ChargeReceiptReport } from './ChargeReceiptReport'

const WIDGET_CONTAINER_CONTENT_MAX_WIDTH = '424px'

export function ChargeReceipt() {
  const { selectedLanguageStrings } = useStoreSelector(state => state)

  return (
    <Root theme="vx" messages={selectedLanguageStrings}>
      <ChargeReceiptWidget />
    </Root>
  )
}

function ChargeReceiptWidget() {
  const { venueInfo, billingHistory, billingHistoryBundle } = useStoreSelector(state => state)
  const { formatMessage } = useLocales()

  const isMobile = useMaxWidthBreakpoint('s')
  const padding = isMobile ? 'm' : 'lm'
  const venuePath = `/explore/${venueInfo.urlKey}/reservations/create/search`
  const hasCardInfo = !!billingHistory.brand && !!billingHistory.last4

  const referenceCode = billingHistoryBundle
    ? billingHistoryBundle
        .filter(bh => bh.actual)
        .map(bh => bh.actual.referenceCode)
        .join(', ')
    : billingHistory.actual.referenceCode

  return (
    <VStack justifyContent="space-between" minHeight="100vh" backgroundColor="secondaryBackground">
      <TopNav logoLabel={venueInfo.name} baseUrl={venuePath} logo={venueInfo.largeLogoUrl} grow={false} />
      <VStack width="100%" flex="1">
        <Flex m="m" alignSelf="center" justifyContent="center" data-test="charge-receipt-page-need-help-text">
          <Text>{billingHistory.charged}</Text>
        </Flex>

        <Flex
          maxWidth={WIDGET_CONTAINER_CONTENT_MAX_WIDTH}
          width="100%"
          alignSelf="center"
          justifyContent="flex-start"
          pl={padding}
          pr={padding}
          data-test="charge-receipt-content"
          flex="1"
          backgroundColor="primaryBackground"
          flexDirection="column"
          alignItems="center"
        >
          <Flex mt="4xl" mb="4xl" alignSelf="center" justifyContent="center">
            <VStack spacing="xs" alignItems="center">
              <Text fontSize="xxl" fontWeight="bold">
                {billingHistory.amount} {formatMessage(chargeReceiptMessages.receiptPageAtLabel)} {venueInfo.name}
                <DividerLine mt="s" mb="m" />
              </Text>
              {hasCardInfo && (
                <Text fontSize="xl">
                  {billingHistory.brand?.toUpperCase()} {billingHistory.last4}
                </Text>
              )}
            </VStack>
          </Flex>

          <Flex mb="4xl" alignSelf="center" justifyContent="center">
            <ChargeReceiptReport />
          </Flex>
        </Flex>
      </VStack>

      <Flex m="m" alignSelf="center" justifyContent="center" data-test="charge-receipt-page-need-help-text">
        <VStack spacing="xs" alignItems="center">
          <Text>
            {formatMessage(chargeReceiptMessages.receiptPageNeedHelpText, {
              venue_name: venueInfo.name,
              phone_number: venueInfo.phoneNumber ? (
                <Link
                  data-test="venue-phone-number"
                  color="primaryFont"
                  textDecoration="underline"
                  whiteSpace="nowrap"
                  to={`tel:${venueInfo.phoneNumber}`}
                  isExternal
                  target="_self"
                >
                  {FormatService.formatPhoneNumber(venueInfo.phoneNumber, venueInfo.countryCode ?? 'us')}
                </Link>
              ) : (
                '---'
              ),
            })}
          </Text>
          <Text>
            {formatMessage(chargeReceiptMessages.commonResNumberText)}
            {referenceCode}
          </Text>
        </VStack>
      </Flex>
      <LogoFooter />
    </VStack>
  )
}
